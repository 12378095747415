import React, { createContext, useContext, useState } from "react";
import Cookies from "universal-cookie";
import * as constants from '../../Components/Constant/Constant'
// Create the context
const UserContext = createContext();

const cookies = new Cookies();

// Provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    fullName: cookies.get("finuser_fullname") || "",
    userImage: cookies.get("finprofile_image") || "",
  });
  const updateUser = (updatedUser) => {
    var thirtyday = new Date(Date.now()+2592000000);
    cookies.set("finuser_fullname", updatedUser.fullName, { path: "/" });
    cookies.set("finprofile_image", updatedUser.userImage, { path: "/" ,  expires: thirtyday, domain: constants.COOKIES_DOMAIN});
    setUser(updatedUser); // Update state to trigger re-renders
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook for accessing the context
export const useUser = () => useContext(UserContext);
