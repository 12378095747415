// export const BASE_URL = "http://localhost:4020/"; //(this for local build)
// export const BASE_URL = "https://www.glintler.com:4020/"; //(this for dev build)
export const BASE_URL = "https://www.glintler.com:4000/";//(this for production build)

// export const BASE_URL_LIVE = "http://localhost:4020/"; //(this for local build)
//export const BASE_URL_LIVE = "https://www.glintler.com:4020/"; //(this for dev build)
export const BASE_URL_LIVE = "https://www.glintler.com:4000/"; //(this for live build)

// export const Local_URL = "http://localhost:4000/";
//export const HOME_APP_URL = "http://localhost:3000/analytics_1_2"; //(this for local build)
 //export const HOME_APP_URL = "https://www.glintler.com/analytics_1_2"; //(this for dev build)
export const HOME_APP_URL = "https://www.refine.finance";//(this for production build)

//export const ADMIN_API_URL = 'https://devapi.glintler.com'; //(this for local build)
//export const ADMIN_API_URL = 'https://glintler.in:3060'; //(this for dev build)
//export const ADMIN_API_URL = 'https://api.glintler.com'; //(this for production build)

//export const COOKIES_DOMAIN = "localhost"; //(this for local build)
//export const COOKIES_DOMAIN = "glintler.com";//(this for dev build)
export const COOKIES_DOMAIN = "refine.finance";//(this for production build)

//export const SOCKET_URL = "http://localhost:4000"; //(this for local build)
//export const SOCKET_URL = "https://www.glintler.com:4020"; //(this for dev build)
//export const SOCKET_URL = "https://www.glintler.com:4000"; //(this for production build)

export const DEFAULT_CHECK_NUMBER = 330;
export const PUBLIC_KEY = randomString();
export const RANDOM_STRING = randomString();
export const DEFAULT_USER_ID = 42;
export const PRIVATE_KEY = "GlinterIndia2018";
function randomString() {
  var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  var string_length = 8;
  var randomstring = "";
  for (var i = 0; i < string_length; i++) {
    var rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
}
export const BG_COLOUR = [
  "#4562ea",
  "#3fe5d1",
  "#ebce42",
  "#e37b3e",
  "#e23ba5",
  "#38c845",
  "#dd3131",
  "#666666",
  "#8599f0",
  "#9cf3ed",
  "#f3dd88",
  "#dfb390",
  "#ed7fc6",
  "#79dd83",
  "#ea7676",
  "#9b9b9b",
  "#354799",
  "#319589",
  "#9a8931",
  "#98542f",
  "#943172",
  "#2e843b",
  "#902c2e",
  "#464646",
  "#9966CC",
  "#008080",
  "#FFF0F5",
  "#6699CC",
  "#003153",
  "#CD5700",
  "#8F8B66",
];
export const CASHFLOW_FORMAT = [
  "operating cash margin",
  "(invested)/generated from net working assets",
  "net capex",
  "taxation",
  "debt service cost paid",
  "dividend & dividend tax paid",
  "investments/loans & advances",
  "increase/(decrease) in equity",
  "increase/(decrease) in debt",
  "(increase)/decrease in cash",
  "exceptional items",
  "other financing related items",
];
export const COST_SPLIT_TO_REVENUE = [
  "Revenue",
  "Cost of Sales",
  "Expenses",
  "Exceptional item",
  "Taxation Expense",
  "Net Profit After Tax",
  "Minority Interest/Associate Profit and Loss",
];
export const CASHFLOW = [
  "net cash generations from operating activities",
  "net cash generations from investing activities",
  "net cash generation from financing activities",
  "net increase/(decrease) in cash and cash equivalents",
  "exceptional items",
];
export const TOTAL_OUTSTANDING_SHARE_FUTURE =
  "total_outstanding_share_including_future_commitement";
export const FREECASHFLOW = [
  "operating cash margin",
  "(invested)/generated from net working assets",
  "net capex",
  "taxation",
  "debt service cost paid",
];
export const TABLEONLY = [
  "Share Price vs Dow jones performance",
  "Stock performance Chart",
  "Earning Yield Comparison",
  "Comparative Price to Earning",
  "Net Asset Value Comparisons",
  "Free Cash Flow Based Valuation",
  "Market Cap Co-relation with Underlying Business",
  "Revenue Profile",
  "Revenue Progression Drivers",
  "Revenue Progression Drivers %",
  "Revenue Profile (% of Total)",
  "P&L Profile for the Company",
  "Opex Profile for the Company",
  "Margin % (Cash Flow Vs P&L)",
  "Sources and Uses of Cash Flow",
  "Sources and Uses of Equity",
  "Return on Equity",
  "Return on Capital Employed",
];
export const TABLEGRAPH = [
  "Contigent Liabilities",
  "Market Capitalization Evolution",
  "Price to Book Value/Price to Sales",
  "Capitalization Structure (Debt & Equity)",
  "Debt to Equity in Capital Structure",
  "Borrowing Profile for the Company",
  "Debt Service Costs Materiality",
  "Debt Service Capability",
  "Operational Metrics",
  "Capex needs for business",
  "Earning before Interest and Tax (EBIT)",
  "Net Profit After Tax",
  "Earning Per Share",
  "Reported Cash Flow Metrics",
  "Reported Cash Flow Metrics",
  "Dividends",
];
export const GRAPHONLY = [
  "Stock Performance Vis-a-Vis Index",
  "Total outstanding share including future commitment",
  "Price Earning Ratio",
  "Cash Flow Yields",
  "Borrowing to Equity Ratio/Total Liabilities to Equity Ratio",
  "Borrowing Profile (% of Total)",
  "Borrowing Interest % for the Company",
  "Depreciation/Amortization for the Company",
  "Current Asset and Acid Test Ratio",
  "Taxation As % of Profit Before Tax",
  "Tax Assets & Liabilities",
  "Exceptional Items Cash Flow And P&L",
  "Sources of Cash Flow",
  "Usage of Cash Flow",
  "Free Cash Flows (W/o Exceptional Items)",
  "Dividend & Dividend Tax (Cash Flow Vs P&L)",
  "Sources of Equity",
  "Usage of Equity",
  "Utilization of Retained Earning",
  "Promoter Shareholding",
  "Managerial Compensation",
  "Sources of Related Party Transaction",
  "Uses of Related Party Transaction",
  "Related Party Transaction",
  "Taxation As % of Profit Before Tax",
  "Margin % (Cash Flow Vs P&L)",
];
export const graphColors = [
  "#1095FC",
  "#BDA277",
  "#FDBE61",
  "#7793A8",
  "#7E776D",
  "#51E3A4",
  "#398ECE",
  "#FB7760",
  "#77A692",
  "#D07262",
  "#3E44D0",
  "#BEF5DD",
  "#F5C7BF",
  "#A2CCBA",
  "#9C685F",
  "#3D755D",
  "#521B11",
  "#D1B53F",
  "#7D6A1A",
  "#CF943A",
  "#022D4F",
  "#CBB28A",
  "#954133",
  "#7D7966",
];

export const currencytext = {
  INR: "Rs.",
  USD: "$",
  RM: "RM",
  EUR: "€",
  CHF: "CHF",
  SEK: "SEK",
  THB: "฿",
  TWD: "NT$",
};
export const defaultPortfolio = [
  {
    portfolio_id: 1276,
    user_id: 38,
    portfolio_name: "Model (US)",
    company_id: 32,
    cagr: "27.06",
    risk: "0.40",
    allocation: "10",
    expected_return: "-1",
    company_name: "Amazon",
    comp_tracking_date: "24-Feb-2014",
    created_at: "2024-08-14T04:59:55.000Z",
    updated_at: "2024-08-14T04:59:55.000Z",
  },
  {
    portfolio_id: 1280,
    user_id: 38,
    portfolio_name: "Model (US)",
    company_id: 27,
    cagr: "19.69",
    risk: "0.20",
    allocation: "20",
    expected_return: "-1",
    company_name: "Apple ",
    comp_tracking_date: "24-Feb-2014",
    created_at: "2024-08-14T04:59:55.000Z",
    updated_at: "2024-08-14T04:59:55.000Z",
  },
  {
    portfolio_id: 1279,
    user_id: 38,
    portfolio_name: "Model (US)",
    company_id: 30,
    cagr: "3.22",
    risk: "0.07",
    allocation: "20",
    expected_return: "-1",
    company_name: "Coca cola",
    comp_tracking_date: "24-Feb-2014",
    created_at: "2024-08-14T04:59:55.000Z",
    updated_at: "2024-08-14T04:59:55.000Z",
  },
  {
    portfolio_id: 1277,
    user_id: 38,
    portfolio_name: "Model (US)",
    company_id: 39,
    cagr: "23.37",
    risk: "0.13",
    allocation: "10",
    expected_return: "-1",
    company_name: "Microsoft",
    comp_tracking_date: "24-Feb-2014",
    created_at: "2024-08-14T04:59:55.000Z",
    updated_at: "2024-08-14T04:59:55.000Z",
  },
  {
    portfolio_id: 1278,
    user_id: 38,
    portfolio_name: "Model (US)",
    company_id: 59,
    cagr: "42.96",
    risk: "1.41",
    allocation: "40",
    expected_return: "-1",
    company_name: "Nvidia",
    comp_tracking_date: "24-Feb-2014",
    created_at: "2024-08-14T04:59:55.000Z",
    updated_at: "2024-08-14T04:59:55.000Z",
  },
];
