import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CompanyProvider } from "./Portfolio/Components/companyDataContext";
import { UserProvider } from "./Profile_And_account/Profile/UserProvider";

const GetStarted = lazy(() => import("./GetStarted/GetStarted"));
const EquityAnalysis = lazy(() => import("./EquityAnalysis/EquityAnalysis"));
const DataSolutions = lazy(() => import("./DataSolutions/DataSolutions"));
const OnDemandEquity = lazy(() => import("./OnDemandEquity/OnDemandEquity"));
const EquityForm = lazy(() => import("./EquityAnalysis/EquityFormContainer"));
const ResetPassword = lazy(() => import("./Login/ResetPassword"));

const NewLanding = lazy(() => import("./LandingPageMarketing/LandingPage"));
const EquityVerification = lazy(() =>
  import("./EquityAnalysis/EmailVerification")
);
const EquityVerificationSuccess = lazy(() =>
  import("./EquityAnalysis/EmailVerificationSuccess")
);

const ManagerialReporting = lazy(() =>
  import("./ManagerialReporting/ManagerialReporting")
);
const Home = lazy(() => import("./HomePage/Index"));
const Profile = lazy(() => import("./pages/ProfileAndAcount"));
const ContactUs = lazy(() => import("./ContactUs/ContactUs"));
const Explore = lazy(() => import("./Explore_Section/Explore"));
const AnalyticalTools = lazy(() => import("./Explore_Section/AnalyticalTool"));
const ReportingTools = lazy(() => import("./Explore_Section/ReportingTool"));
const Feature = lazy(() => import("./Feature_Section/Feature"));
const TermsAndConditions = lazy(() =>
  import("./TermsAndConditions/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("./PrivacyAndPolicy/PrivacyPolicy"));
//import Analytics from "./Analytics_Section/Analytics";
//import Benchmark from "./Analytics_Section/Benchmark";
const Portfolio = lazy(() => import("./pages/Portfolio"));
const Screener = lazy(() => import("./pages/Screener"));
const Company = lazy(() => import("./pages/analytics"));
const Company2 = lazy(() => import("./pages/analyticsSpeed"));
const AboutUs = lazy(() => import("./AboutUs/AboutUs"));
const SearchDropdown = lazy(() => import("./pages/apitestpagewithlazyloading"));
const CompanyCompare = lazy(() => import("./Container/Company"));

const PortfolioWithContext = () => (
  <CompanyProvider>
    <Portfolio />
  </CompanyProvider>
);
const ScreenerWithContext = () => (
  <CompanyProvider>
    <Screener />
  </CompanyProvider>
);

const ProfileWithContext = () => (
  <UserProvider>
    <Profile />
  </UserProvider>
);
function App() {
  return (
    <BrowserRouter basename="/">
      <UserProvider>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/explore" element={<Explore />} /> */}
          {/* <Route path="/explore/:exploresection" element={<Explore />} /> */}

          <Route path="/analytical-tools" element={<AnalyticalTools />} />
          <Route path="/reporting-tools" element={<ReportingTools />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/real-estate-dashboard" element={<NewLanding />} />
          <Route path="/changepassword/" element={<ResetPassword />} />
          <Route path="/feature/:featurename" element={<Feature />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/analytics" element={<Company />} />
          {/* <Route path="/analytics2" element={<Company2 />} /> */}
          <Route path="/equity-analysis" element={<EquityAnalysis />} />
          <Route path="/equity-data-solutions" element={<DataSolutions />} />
          <Route
            path="/on-demand-equity-analysis"
            element={<OnDemandEquity />}
          />
          <Route path="/equity-analysis/sign-up" element={<EquityForm />} />
          <Route path="/thank-you" element={<EquityVerification />} />
          <Route
            path="/verifylink/:id"
            element={<EquityVerificationSuccess />}
          />
          <Route path="/profile" element={<Profile />} />

          <Route
            path="/analytics/:companyname/financial/:financial"
            element={<Company />}
          />
          {/* 
        <Route
          path="/analytics2/:companyname/financial/:financial"
          element={<Company2 />}
        /> */}
          <Route
            path="/analytics/:companyname/financial/:financial/:subcompanyname"
            element={<Company />}
          />
          {/* <Route
          path="/analytics2/:companyname/financial/:financial/:subcompanyname"
          element={<Company2 />}
        /> */}
          <Route path="/portfolio" element={<PortfolioWithContext />} />
          <Route path="/screener" element={<ScreenerWithContext />} />
          {/* <Route path="/profile" element={<ProfileWithContext />} /> */}

          {/* <Route path="/benchmark" element={ <Benchmark/>} />*/}
          <Route path="/get-started" element={<GetStarted />} />
          <Route
            path="/managerial-reporting"
            element={<ManagerialReporting />}
          />
          <Route path="/test" element={<SearchDropdown />} />
          <></>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/CompanyCompare" element={<CompanyCompare />} />
          <Route
            path="/CompanyCompare/:companyname/financial/:financial"
            element={<CompanyCompare />}
          />
          <Route
            path="/CompanyCompare/:companyname/financial/:financial"
            element={<CompanyCompare />}
          />
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}
export default App;
